import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Button from './Button'

const CaseStudiesArchiveFilters = props => {
  const data = useStaticQuery(graphql`
    query CaseStudiesFiltersQuery {
        tags: allStoryblokEntry(filter: {field_component: {eq: "case-study"}}) {
            group(field: tag_list) {
                fieldValue
            }
        }
    }
  `)

  return (
    <div className="flex flex-row flex-wrap gap-x-4 gap-y-4 mb-12">
        <Button
            target="/case-studies/"
            className="bg-red hover:bg-darkred"
            activeClassName="!bg-darkred"
        >
        All case studies
        </Button>
        {data.tags.group.map((item) => {
            return (
                <Button
                    target={`/case-studies/category/${item.fieldValue.replace(/\s/g, "-").toLowerCase()}/`}
                    className="bg-red hover:bg-darkred"
                    activeClassName="!bg-darkred"
                    key={item.fieldValue}
                >
                {item.fieldValue}
                </Button>
            )
        })}
    </div>
  )
}

export default CaseStudiesArchiveFilters
