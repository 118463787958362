import React from "react";
import CaseStudiesArchive from "../components/CaseStudiesArchive";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Seo from 'src/utils/seo'

export default class BlogArchiveTemplate extends React.Component {
  render() {
    return (
        <Layout location={this.props.location}>
            <Seo pageName={'Case Studies Archive'}/> 
            <CaseStudiesArchive posts={this.props.data.posts.edges} caseStudiesNumPages={this.props.pageContext.caseStudiesNumPages} currentPage={this.props.pageContext.currentPage} />
        </Layout>
    );
  }
}

export const query = graphql`
    query getAllCaseStudies($skip: Int!, $limit: Int!) {
        posts: allStoryblokEntry(
            limit: $limit
            skip: $skip
            filter: {field_component: {eq: "case-study"}}
            sort: {fields: first_published_at, order: DESC}
        ) {
            edges {
                node {
                    name
                    content
                    full_slug
                    uuid
                    first_published_at
                }
            }
        }
    }
`;
